@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif !important;
}
h1,h2,h3,h4,h5,h6{
  margin: 0px;
  padding: 0px;
}
p{
  margin: 0px;
  padding: 0px;
}
.termsCondtionContent{
  border: none;
}
.termsCondtionContent .ql-toolbar{
  border: none;
  padding: 16px 16px;
  background: #D0BCFF;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.termsCondtionContent .ql-toolbar .ql-formats button{
  width: 30px;
  height: 30px;
}
.termsCondtionContent .ql-container {
  border: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.termsCondtionContent .ql-container .ql-editor {
  min-height: 300px;
  overflow-y: auto;
  height: 200px;
}

/* Vertical Scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Width of the vertical scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee; /* Background color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6ee498; /* Color of the scrollbar thumb */
  border-radius: 30px; /* Rounded corners for the thumb */
}

/* Horizontal Scrollbar */
::-webkit-scrollbar-horizontal {
  height: 5px; /* Height of the horizontal scrollbar */
}

/* Horizontal Handle */
::-webkit-scrollbar-thumb-horizontal {
  background: rgba(137, 209, 240, 1); /* Color of the horizontal scrollbar thumb */
  border-radius: 30px; /* Rounded corners for the horizontal thumb */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.inputClassCountry {
  padding: 0px 0px 0px 0px !important;
  width: 100% !important;
  border: none;
  border-radius: 8px !important;
  padding-left: 60px !important;
  background: white !important;
  height: 56px !important;
  
}
.inputClassCountry.error {
  border: 1px solid red;
}
.inputClassCountry:focus {
  outline: none !important;
  box-shadow: none !important;
}
.flag-dropdown {
  background-color: transparent !important;
  border: 0px !important;
}
.flag-dropdown .selected-flag {
  padding: 0px !important;
  width: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flag-dropdown .selected-flag:hover {
  background-color: transparent !important;
}